import React, { Component, useState, useEffect } from "react";
import {
    Accordion,
    Col,
    Container,
    Form,
    Row,
    Button,
    Modal,
    Image,
    Card,
    Spinner,
    FormGroup,
    FormControl,
} from "react-bootstrap";
import {
    BiChevronLeft,
    BsFileEarmarkText,
    AiOutlineFilePdf,
    BiVideo,
    BiTrash,
    FiEdit3,
} from "react-icons/all";
import DatePicker from "react-datepicker";
import { getToken, logout } from "../../../resources/utils";
import { deleteApi, postApi } from "../../../services/axios";
import { v4 as uuidv4 } from "uuid";
import { getAllCategory } from "../../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import {
    createCourse,
    createCourseSection,
    updateCourse,
    updateCourseSection,
} from "../../../resources/course";
import {
    ADD_COURSE,
    ADD_COURSE_SECTION,
    COURSE_ERROR,
    GET_COURSE_DETAIL,
    UPDATE_COURSE,
    UPDATE_COURSE_SECTION,
} from "../../../redux/types";
import swal from "sweetalert";
import { getCourseID } from "../../../redux/actions/courseAction";
import { Link } from "react-router-dom";
import { validURL } from "../../../resources/helpers";

function ModalAttachment(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Konten
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
            <Modal.Footer>
                <Button className="btn-clear" onClick={props.onHide}>
                    Close
                </Button>
                <Button onClick={props.onSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
}

const Section = ({ count, uuid, data, courseId, deleteCourse, allSection }) => {
    const dispatch = useDispatch();
    const [showing, setShows] = useState(true);
    const [content, setContents] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [error, setError] = useState([]);
    const [errorTitle, setErrorTitle] = useState([]);
    const [errorEndDate, setErrorEndDate] = useState([]);
    const [errorAttachment, setErrorAttachment] = useState([]);
    const [errorDescription, setErrorDescription] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const [title, setTitle] = useState([]);
    const [description, setDescription] = useState([]);
    const [detail, setDetail] = useState(data);
    const [modalShow, setModalShow] = useState(false);

    const addTextArea = (id) => {
        setAttachment({ [id]: "description" });
        setContents((content) => [
            ...content,
            <Form.Control
                key={count}
                as="textarea"
                name={"description" + count}
                placeholder="Masukkan deskripsi modul..."
                onChange={(e) => {
                    setDescription({ [count]: e.target.value });
                }}
                onFocus={() => {
                    setErrorDescription({ [count]: "" });
                }}
            />,
        ]);
    };

    const addFile = (id) => {
        setAttachment({ [id]: "file" });
        setContents((content) => [
            ...content,
            <Form.Control
                key={count}
                name={"description" + count}
                type="text"
                placeholder="Masukkan link file..."
                onChange={(e) => {
                    setDescription({ [count]: e.target.value });
                }}
                onFocus={() => {
                    setErrorDescription({ [count]: "" });
                }}
            />,
        ]);
    };

    const addVideo = (id) => {
        setAttachment({ [id]: "video" });
        setContents((content) => [
            ...content,
            <Form.Control
                key={count}
                name={"description" + count}
                type="text"
                placeholder="Masukkan link video..."
                onChange={(e) => {
                    setDescription({ [count]: e.target.value });
                }}
                onFocus={() => {
                    setErrorDescription({ [count]: "" });
                }}
            />,
        ]);
    };

    const onDeleteByIndex = (index) => {
        const newCountries = [...content];
        newCountries.splice(index, 1);
        setShows(true);
        setContents(newCountries);

        const newAttachment = [...attachment];
        newAttachment.splice(index, 1);
        setAttachment(newAttachment);
    };

    function formatDate(str) {
        var d = new Date(str),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var dateFormat = [year, month, day].join("-");
        return dateFormat;
    }

    const removeAlert = (e) => {
        if (e.target.name === "title" + e.target.name) {
            setErrorTitle([]);
            setError([]);
        } else if (e.target.name === "date" + e.target.name) {
            setErrorEndDate([]);
            setError([]);
        }
    };

    const submitContent = () => {
        const data = new FormData(document.getElementById("content"));
        setAttachment([
            ...attachment,
            {
                type: data.get("type"),
                title: data.get("title"),
                value: data.get("link"),
            },
        ]);

        if (!validURL(data.get("link"))) {
            setErrorAttachment("URL tidak valid.");
        }

        setModalShow(false);
    };

    const deleteAttachment = (index) => {
        const filteredAttach = attachment.filter((val, i) => i !== index);

        setAttachment(filteredAttach);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        var id = event.target.name;

        if (!title[id] || !attachment || endDate <= startDate) {
            if (!title[id]) {
                setErrorTitle({ [id]: "Judul modul kosong." });
            }

            if (!title[id]) {
                setErrorTitle({ [id]: "Judul modul kosong." });
            }

            if (!attachment) {
                setErrorAttachment("Anda belum menambahkan konten.");
            }

            if (endDate <= startDate) {
                setErrorEndDate({
                    [id]: "Tanggal selesai tidak dapat lebih kecil atau sama dengan tanggal dimulai.",
                });
            }

            return;
        }

        const data = new FormData(document.getElementById("section" + id));

        data.set("key", uuid);
        data.set("status", "active");
        data.set("title", title[id]);
        data.set("open_date", formatDate(startDate));
        data.set("closed_date", formatDate(endDate));
        data.set("attachment", JSON.stringify(attachment));

        try {
            const res = await createCourseSection(data);

            dispatch({
                type: ADD_COURSE_SECTION,
                payload: res,
            });

            if (res.status !== "success" || res === undefined) {
                if (res.status_code === 422) {
                    if (res.data.title) {
                        setErrorTitle({ [id]: "Judul modul kosong." });
                    }
                } else {
                    setError(
                        "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                    );
                }
            } else {
                swal({
                    title: "Sukses!",
                    text: "Module " + title[count] + " sudah disimpan!",
                    icon: "success",
                    button: "Ok!",
                });
            }
        } catch (error) {
            dispatch({
                type: COURSE_ERROR,
                payload: false,
            });
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();

        const param = {
            title: title[count],
            description: description[count],
            open_date: formatDate(startDate),
            closed_date: formatDate(endDate),
            attachment: JSON.stringify(attachment),
        };

        try {
            const res = await updateCourseSection(param, detail.id);

            dispatch({ type: UPDATE_COURSE_SECTION, payload: res });

            if (res.status !== "success" || res === undefined) {
                setError(
                    "Terjadi kesalahan, silahkan coba beberapa saat lagi."
                );
            } else {
                swal({
                    title: "Sukses!",
                    text: "Modul " + title[count] + " berhasil diubah!",
                    icon: "success",
                    button: "Ok!",
                });
            }
        } catch (error) {
            dispatch({
                type: COURSE_ERROR,
                payload: false,
            });
        }
    };

    const deleteSection = async (e) => {
        e.preventDefault();

        swal({
            title: "Apakah anda yakin?",
            text: "Data yang sudah dihapus tidak dapat dikembalikan!",
            icon: "warning",
            buttons: {
                cancel: "Batal",
                danger: {
                    text: "Hapus",
                    value: true,
                },
            },
            dangerMode: true,
        }).then((value) => {
            if (detail) {
                if (value) {
                    const url = `course/section/delete/${detail.id}`;
                    const token = "Bearer " + getToken();
                    const headers = {
                        headers: {
                            Authorization: token,
                        },
                    };
                    deleteApi(url, headers)
                        .then((res) => {
                            if (res.status !== "error") {
                                const filteredSection = allSection.filter(
                                    (section) => section.id !== detail.id
                                );
                                deleteCourse(filteredSection);
                                dispatch(getAllCategory());
                                dispatch(getCourseID(courseId));
                                swal(
                                    "Module " +
                                        title[count] +
                                        " berhasil dihapus!",
                                    {
                                        icon: "success",
                                    }
                                );
                            } else {
                                swal(
                                    "Module " +
                                        title[count] +
                                        " gagal dihapus!",
                                    {
                                        icon: "error",
                                        buttons: { cancel: "Kembali" },
                                    }
                                );
                            }
                        })
                        .catch((err) => console.log(err));
                }
            } else {
                let tit = title[count] ? title[count] : "Module " + count;
                swal("Module " + tit + " berhasil dihapus!", {
                    icon: "success",
                });

                const filteredSection = allSection.filter(
                    (section) => section.id !== detail.id
                );
                deleteCourse(filteredSection);
            }
        });
    };

    useEffect(() => {
        if (data) {
            setAttachment(JSON.parse(detail.attachment));
            setStartDate(new Date(detail.open_date));
            setEndDate(new Date(detail.closed_date));
            setTitle({ [count]: detail.title });
            setDescription({ [count]: detail.description });
        }
    }, [detail]);

    return (
        <>
            <Accordion.Item eventKey={count} key={count}>
                <Accordion.Header>
                    {title[count] ? title[count] : "Modul " + count}
                </Accordion.Header>
                <Accordion.Body>
                    <Form key={count} id={"section" + count}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Judul</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Masukkan judul modul..."
                                onFocus={() => {
                                    setErrorTitle({ [count]: "" });
                                }}
                                name={"title" + count}
                                value={title[count]}
                                onChange={(e) => {
                                    setTitle({ [count]: e.target.value });
                                }}
                            />
                            {errorTitle[count] && (
                                <>
                                    <Form.Text className="text-alert">
                                        *{errorTitle[count]}
                                    </Form.Text>
                                </>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Deskripsi</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                placeholder="Masukkan deskripsi kursus..."
                                value={description[count]}
                            />
                        </Form.Group>
                        <div className="date">
                            <span>
                                Mulai
                                <DatePicker
                                    className="form-control"
                                    selected={startDate}
                                    name={"start" + count}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setErrorEndDate([]);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </span>
                            <span>
                                Hingga
                                <DatePicker
                                    readOnly={true}
                                    className="form-control"
                                    selected={endDate}
                                    name={"end" + count}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        setErrorEndDate([]);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </span>
                        </div>
                        {errorEndDate[count] && (
                            <>
                                <Form.Text className="text-alert">
                                    *{errorEndDate[count]}
                                </Form.Text>
                            </>
                        )}

                        <Row>
                            {attachment
                                ? attachment.map((val, i) => (
                                      <Col xs={12} md={6} key={i}>
                                          <Card className="padding-lr30">
                                              <div
                                                  className="item-element"
                                                  key={i}
                                              >
                                                  <span className="text-label12">
                                                      {val.type}
                                                  </span>
                                                  <div>{val.title}</div>
                                                  <a
                                                      href={val.value}
                                                      className="text-label12 ellipsis"
                                                  >
                                                      {val.value}
                                                  </a>
                                                  <div className="delete-container">
                                                      <Button
                                                          className="btn btn-delete-content text-white"
                                                          onClick={() =>
                                                              deleteAttachment(
                                                                  i
                                                              )
                                                          }
                                                      >
                                                          <BiTrash />
                                                      </Button>
                                                  </div>
                                              </div>
                                          </Card>
                                          {errorAttachment[count] && (
                                              <>
                                                  <Form.Text className="text-alert">
                                                      *{errorAttachment[count]}
                                                  </Form.Text>
                                              </>
                                          )}
                                      </Col>
                                  ))
                                : ""}
                        </Row>

                        <div style={{ display: showing ? "flex" : "none" }}>
                            <Button
                                className="btn btn-primary margin-tb30 w-100"
                                onClick={() => {
                                    setModalShow(true);
                                    console.log(attachment);
                                }}
                            >
                                Tambah Konten
                            </Button>
                        </div>

                        <input
                            className="btn btn-primary"
                            onClick={detail ? handleUpdate : handleSubmit}
                            name={count}
                            type="submit"
                            value="Simpan"
                            style={{ marginRight: "10px" }}
                        />

                        <button
                            onClick={deleteSection}
                            className="btn btn-danger display-inline-block"
                        >
                            <BiTrash /> Hapus
                        </button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>

            <ModalAttachment
                show={modalShow}
                onSubmit={submitContent}
                onHide={() => setModalShow(false)}
            >
                <Form onSubmit={submitContent} id="content">
                    <label className="display-block">Pilih Tipe Konten</label>
                    <Form.Select
                        name="type"
                        aria-label="Default select example"
                        defaultValue=""
                    >
                        <option disabled value="">
                            Pilih tipe konten
                        </option>
                        <option value="video">Video</option>
                        <option value="file">File</option>
                    </Form.Select>
                    <FormGroup className="margin-t10">
                        <label className="display-block">Judul</label>
                        <FormControl
                            aria-label="Masukkan judul ..."
                            aria-describedby="basic-addon2"
                            name="title"
                            type="text"
                            placeholder="Masukkan judul ..."
                        />
                    </FormGroup>
                    <FormGroup className="margin-t10">
                        <label className="display-block">Link</label>
                        <FormControl
                            aria-label="Masukkan link ..."
                            aria-describedby="basic-addon2"
                            name="link"
                            type="text"
                            placeholder="Masukkan link ..."
                        />
                    </FormGroup>
                </Form>
            </ModalAttachment>
        </>
    );
};

const EditCourse = (props) => {
    const dispatch = useDispatch();
    const categoryList = useSelector((state) => state.category);
    const { categories } = categoryList;
    const courseDetail = useSelector((state) => state.courses);
    const { course, category, detail } = courseDetail;
    const [detailData, setDetailData] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [number, setNumber] = useState(1);
    const [section, setSection] = useState([]);
    const [isScroll, setScroll] = useState("absolute");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [file, setFile] = useState(null);
    const [size, setSize] = useState(null);
    const [name, setName] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uuid, setUuid] = useState("");
    const [error, setError] = useState("");
    const [errorTitle, setErrorTitle] = useState(null);
    const [errorEndDate, setErrorEndDate] = useState(null);
    const [errorCategory, setErrorCategory] = useState(null);
    const [cat, setCategory] = useState([]);
    const [cate, setCate] = useState([]);
    const [loading, setLoading] = useState(true);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setErrorEndDate(null);
    };

    const addSection = () => {
        setNumber(number + 1);
        setSection([
            ...section,
            <Section
                key={number}
                count={number}
                uuid={uuid}
                data={false}
                courseId={courseData.id}
                deleteCourse={setSection}
                allSection={section}
            />,
        ]);
    };

    function formatDate(str) {
        var d = new Date(str),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var dateFormat = [year, month, day].join("-");
        return dateFormat;
    }

    const fileChange = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        if (reader !== undefined && file !== undefined) {
            reader.onloadend = () => {
                setFile(file);
                setSize(file.size);
                setName(file.name);
                setPreview(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleChange = (event) => {
        setErrorCategory(null);

        setCourseData({
            ...courseData,
            [event.target.name]: event.target.value,
        });

        console.log(event.target.name);
        console.log(event.target.value);
        console.log(courseData);
    };

    const handleCategory = (event) => {
        setCategory({
            ...cat,
            id: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            title: courseData.title,
            description: courseData.description,
            image: preview !== null ? preview : courseData.image,
            status: courseData.status,
            open_date: formatDate(startDate),
            closed_date: formatDate(endDate),
            category_id: cat.id,
            key: courseData.key,
        };

        try {
            const res = await updateCourse(data, props.match.params.id);

            dispatch({
                type: UPDATE_COURSE,
                payload: res,
            });

            if (res.status !== "success" || res === undefined) {
                setError(
                    "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                );
            } else {
                swal({
                    title: "Sukses!",
                    text: "Data kursus sudah diubah!",
                    icon: "success",
                    button: "Kembali!",
                }).then(() => {
                    dispatch({
                        type: GET_COURSE_DETAIL,
                        payload: false,
                    });
                    props.history.goBack();
                });
            }
        } catch (e) {
            dispatch({
                type: COURSE_ERROR,
                payload: false,
            });
        }
    };

    const removeAlert = (e) => {
        if (e.target.name === "title") {
            setErrorTitle(null);
            setError(null);
        } else if (e.target.name === "date") {
            setErrorEndDate(null);
            setError(null);
        }
    };

    useEffect(() => {
        dispatch(getAllCategory());
        dispatch(getCourseID(props.match.params.id));
        setTimeout(
            function () {
                setLoading(false);
            }.bind(this),
            1000
        );
    }, []);

    useEffect(() => {
        setCourseData(course);
        setCate(categories);
        setCategory(category);
        if (course) {
            setStartDate(new Date(course.open_date));
            setEndDate(new Date(course.closed_date));
            setUuid(course.key);
        }
        if (detail) {
            setDetailData(detail);
        }
        setNumber(detail.length + 1);
        setPreview(courseData.image);
    }, [course]);

    return (
        <div className="margin-t30 learn-page">
            <div className="top-header margin-b30">
                <div className="title">
                    <FiEdit3 />
                    <h3>Edit Kursus</h3>
                </div>
            </div>
            <div className="learn-create">
                <Form onSubmit={handleSubmit}>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form learn-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Judul</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            onFocus={removeAlert}
                                            placeholder="Masukkan judul kursus..."
                                            value={courseData.title}
                                            onChange={handleChange}
                                        />
                                        {errorTitle && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorTitle}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Deskripsi</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            placeholder="Masukkan deskripsi kursus..."
                                            value={courseData.description}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Accordion>
                                        {detail
                                            ? detailData.map((v, i) => (
                                                  <Section
                                                      key={i + 1}
                                                      count={i + 1}
                                                      uuid={uuid}
                                                      data={v}
                                                      courseId={courseData.id}
                                                      deleteCourse={
                                                          setDetailData
                                                      }
                                                      allSection={detailData}
                                                  />
                                              ))
                                            : ""}
                                        {section}
                                    </Accordion>
                                    <a
                                        className="section-btn"
                                        onClick={addSection}
                                    >
                                        Tambahkan Modul
                                    </a>
                                    <br />

                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}
                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>

                                    <Link
                                        to="/lms/learn"
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="card-form learn-form">
                                    <div className="date">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="status"
                                            value={courseData.status}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>
                                                Pilih Status
                                            </option>
                                            <option value="active">
                                                Aktif
                                            </option>
                                            <option value="not-active">
                                                Tidak Aktif
                                            </option>
                                            <option value="draft">Draft</option>
                                        </Form.Select>
                                    </div>
                                    <div className="date">
                                        <Form.Label>Kategori</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="category"
                                            value={cat.id}
                                            onChange={handleCategory}
                                        >
                                            <option value="" disabled>
                                                Pilih kategori
                                            </option>
                                            {cate
                                                ? cate.map((cat, i) => (
                                                      <option
                                                          key={i}
                                                          defaultValue={cat.id}
                                                          value={cat.id}
                                                      >
                                                          {cat.title}
                                                      </option>
                                                  ))
                                                : ""}
                                        </Form.Select>

                                        {errorCategory && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorCategory}
                                                </Form.Text>
                                            </>
                                        )}
                                    </div>
                                    <div className="date">
                                        <Form.Label>Jadwal Aktif</Form.Label>
                                        <DatePicker
                                            onChange={onChange}
                                            selected={startDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            inline
                                            name="date"
                                        />
                                        {errorEndDate && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorEndDate}
                                                </Form.Text>
                                            </>
                                        )}
                                    </div>
                                    <div className="date">
                                        <Form.Label>Gambar</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={fileChange}
                                        />
                                    </div>
                                    {loading ? (
                                        <div className="relative margin-b50">
                                            <Spinner
                                                animation="border"
                                                className="p-absolute left50"
                                            />
                                        </div>
                                    ) : (
                                        <Image
                                            className="relative img-fit-cover img-size-100"
                                            src={
                                                preview
                                                    ? preview
                                                    : require("../../../assets/images/placeholder.png")
                                                          .default
                                            }
                                            alt="course thumbnail"
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default EditCourse;
