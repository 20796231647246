import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import {
    BiChevronRight,
    BiCog,
    BiConversation,
    BiHomeAlt,
    BiLogOut,
    BiMessageSquareDetail,
    BiSearch,
    BiUser,
    BsCardChecklist,
    BiX,
    BsPeople,
    BiLockOpen,
    BiArchive,
} from "react-icons/all";
import "../routes/PrivateRoute/private.scss";
import {
    getToken,
    logout,
    roleId,
    userId,
    userPermissions,
} from "../resources/utils";
import { getApi } from "../services/axios";
import { getPermissionById } from "../resources/permission";
import { getUserProfile } from "../resources/user";
import {
    getRandomColor,
    createImageFromInitials,
} from "../resources/utilities";
import { useDispatch, useSelector } from "react-redux";
import { getUnverifiedCount } from "../redux/actions/userActions";
import { CheckAdmin } from "../resources/helpers";

function Sidenav(props) {
    const dispatch = useDispatch();
    const userList = useSelector((state) => state.users);
    const { unverifiedCount } = userList;
    let history = useHistory();
    const [scroll, setScroll] = useState(false);
    const [user, setUser] = useState([]);
    const [userPerm, setUserPermission] = useState([]);

    function handlelogout() {
        logout();
        history.push("/login");
    }
    function goProfile() {
        userPerm.map((data) =>
            data === "access_profile" ? history.push("/lms/profile") : ""
        );
    }
    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }

    const getProfile = async () => {
        setUser(await getUserProfile());
    };

    useEffect(() => {
        dispatch(getUnverifiedCount());
    }, []);

    useEffect(() => {
        const toggle = document.getElementById("toggle");
        const sidebar = document.getElementById("mySidenav");
        if (window.screen.width < 768) {
            document.onclick = function (e) {
                if (e.target.id !== "mysideNav" && e.target.id !== "toggle") {
                    sidebar.style.width = "0";
                }
            };
        }

        getProfile();
        setUserPermission(userPermissions());
    }, []);
    return (
        <div className="sideNav-wrap">
            <div id="mySidenav" className="sidenav-dash">
                <button className="openbtn" onClick={() => closeNav()}>
                    <BiX size={35} />
                </button>
                <div className="avatar-box" onClick={() => goProfile()}>
                    <div className="avatar-img">
                        <img
                            id="preview"
                            src={createImageFromInitials(
                                50,
                                user.name,
                                getRandomColor()
                            )}
                            alt="profile-pic"
                        />
                    </div>
                    <div className="avatar-id">
                        <h5>{user.name}</h5>
                        <span>{user.email}</span>
                    </div>
                    <BiChevronRight />
                </div>
                <Nav className="flex-column">
                    {userPerm.map((data, i) => (
                        <NavLink
                            exact
                            key={i}
                            to={
                                data === "access_dashboard"
                                    ? "/lms/dashboard"
                                    : data === "access_course"
                                    ? "/lms/learn"
                                    : data === "access_forum"
                                    ? "/lms/forum"
                                    : data === "access_user"
                                    ? "/lms/user"
                                    : data === "access_roles"
                                    ? "/lms/role"
                                    : data === "access_categories"
                                    ? "/lms/category"
                                    : ""
                            }
                            className="link"
                            activeClassName="navbar__link--active"
                        >
                            {data === "access_dashboard" ? (
                                <>
                                    <BiHomeAlt size={20} />
                                    Beranda
                                </>
                            ) : data === "access_course" ? (
                                <>
                                    <BiMessageSquareDetail size={20} />
                                    Kursus
                                </>
                            ) : data === "access_forum" ? (
                                <>
                                    <BiConversation size={20} /> Forum
                                </>
                            ) : data === "access_user" ? (
                                <>
                                    {/* <div className="notif-dot">
                                        {unverifiedCount}
                                    </div> */}
                                    <BsPeople size={20} /> Pengguna
                                </>
                            ) : data === "access_roles" ? (
                                <>
                                    <BiLockOpen size={20} /> Peran Pengguna
                                </>
                            ) : data === "access_categories" ? (
                                <>
                                    <BiArchive size={20} /> Kategori
                                </>
                            ) : (
                                ""
                            )}
                        </NavLink>
                    ))}
                </Nav>
                <a className="logoutBtn" onClick={() => handlelogout()}>
                    <BiLogOut /> Keluar
                </a>
            </div>
        </div>
    );
}

export function PrivateHeader(props) {
    const [scroll, setScroll] = useState(false);
    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
    }

    return (
        <Navbar
            className="user-nav"
            collapseOnSelect
            variant="light"
            style={{ justifyContent: "space-between" }}
        >
            <div>
                <div className="brand-dashboard hide-desktop">
                    <a href="/">
                        <img
                            alt="Content brand"
                            src={require("../assets/images/logo.png").default}
                        />
                    </a>
                </div>
            </div>
            <div
                id="toggle"
                className="closebtn private"
                onClick={() => openNav()}
            ></div>
        </Navbar>
    );
}
export default Sidenav;
