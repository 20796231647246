import React, { useState } from "react";
import login from "../../assets/images/login.svg";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import "./register.scss";
import {
    ImGoogle,
    ImFacebook,
    IoMail,
    IoLockClosed,
    IoPerson,
    AiOutlineLoading3Quarters,
} from "react-icons/all";
import { Link } from "react-router-dom";
import { postApi } from "../../services/axios";

function Register(props) {
    const [loading, setLoading] = useState(false);
    const name = useFormInput("");
    const email = useFormInput("");
    const password = useFormInput("");
    const [error, setError] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleRegister = (event) => {
        setError(null);
        setNameError(null);
        setEmailError(null);
        setPasswordError(null);
        setSuccess(null);

        event.preventDefault();
        setLoading(true);

        const header = {};

        const data = {
            name: name.value,
            email: email.value,
            password: password.value,
        };

        postApi("auth/register", data, header).then((result) => {
            setLoading(false);
            if (result.status_code === 422) {
                if (result.data.name) {
                    setEmailError(result.data.name);
                }

                if (result.data.email) {
                    setEmailError(result.data.email);
                }

                if (result.data.password) {
                    setPasswordError(result.data.password);
                }
            } else if (result.status_code !== 200) {
                setError(result.message);
            } else {
                setSuccess("Registrasi berhasil");
            }
        });
    };

    const removeAlert = (e) => {
        if (e.target.id === "password") {
            setPasswordError(null);
            setError(null);
            setSuccess(null);
        } else if (e.target.id === "email") {
            setEmailError(null);
            setError(null);
            setSuccess(null);
        } else if (e.target.id === "username") {
            setNameError(null);
            setError(null);
            setSuccess(null);
        }
    };

    return (
        <>
            <Container className="padding-tb30">
                <h1 className="text-center">Daftar</h1>
                <p className="text-center">
                    Daftarkan diri anda untuk bergabung dengan Kelas Lentera
                </p>
            </Container>
            <Container>
                <Card className="padding30 margin-b50">
                    <Row>
                        <Col lg={7} className="relative d-none d-lg-block">
                            <img alt="Content register" src={login} />
                        </Col>
                        <Col lg={5} sm={12}>
                            <Form onSubmit={handleRegister}>
                                <div className="margin-p75">
                                    {nameError && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{nameError}
                                            </Form.Text>
                                        </>
                                    )}
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="text"
                                                className="input-form"
                                                placeholder="John Doe"
                                                {...name}
                                                onFocus={removeAlert}
                                                autoComplete="off"
                                            />
                                            <label className="input-label">
                                                Full Name
                                            </label>
                                            <IoPerson className="icon-btnl left" />
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-p75">
                                    {emailError && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{emailError}
                                            </Form.Text>
                                        </>
                                    )}
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="text"
                                                className="input-form"
                                                placeholder="kelaslentera@mail.id"
                                                {...email}
                                                onFocus={removeAlert}
                                                autoComplete="off"
                                            />
                                            <label className="input-label">
                                                Email
                                            </label>
                                            <IoMail className="icon-btnl left" />
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-p75">
                                    {passwordError && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{passwordError}
                                            </Form.Text>
                                        </>
                                    )}
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="password"
                                                className="input-form input-password"
                                                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                                                {...password}
                                            />
                                            <label className="input-label">
                                                Password
                                            </label>
                                            <IoLockClosed className="icon-btnl left" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a
                                        onClick={handleRegister}
                                        className="btn-primary-lg btn-w100 text-center"
                                    >
                                        {loading === true ? (
                                            <div>
                                                <AiOutlineLoading3Quarters className="loading-spin" />
                                            </div>
                                        ) : (
                                            "Daftar"
                                        )}
                                    </a>
                                    {error && (
                                        <div className="text-center">
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                        </div>
                                    )}
                                    {success && (
                                        <div className="text-center">
                                            <Form.Text className="text-success">
                                                *{success}
                                            </Form.Text>
                                        </div>
                                    )}
                                </div>
                                <p className="line-lr text-center padding-tb30 margin-p75">
                                    atau
                                </p>
                                <a
                                    href="#google"
                                    className="btn-white btn-w100 text-center"
                                >
                                    <ImGoogle className="icon-btn left" />
                                    Masuk dengan Google
                                </a>
                                <a
                                    href="#google"
                                    className="btn-white btn-w100 text-center"
                                >
                                    <ImFacebook className="icon-btn left" />
                                    Masuk dengan Facebook
                                </a>
                                <div className="margin-p75 text-center">
                                    <p>
                                        Sudah memiliki akun?{" "}
                                        <Link
                                            to="/login"
                                            className="btn-clear underline text-bold600"
                                        >
                                            Masuk disini
                                        </Link>
                                    </p>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
}

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return {
        value,
        onChange: handleChange,
    };
};

export default Register;
